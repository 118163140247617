import React from "react";

export default function MenuTitle({ bold, descr, children }) {
	return (
		<div className="menuTitle">
			{children}
			{bold && (
				<p>
					<span className="bold">#{bold}</span>
					{descr && ` ｜ ${descr}`}.
				</p>
			)}
		</div>
	);
}
